import React, { useState } from "react";
import { GatsbyImage } from "gatsby-plugin-image";
import { graphql, useStaticQuery } from "gatsby";
import styled from "styled-components";
import { renderRichText } from "gatsby-source-contentful/rich-text";
import { IoIosArrowDown, IoMdOpen, IoIosCheckmark } from "react-icons/io";
import { IoLocation } from "react-icons/io5";

import { EmptyState } from "../components/feedback";
import { Container, Wrapper, Row, Box, Button } from "../components/util";
import Layout from "../components/layout";
import Seo from "../components/seo";

import FlexiTimePartnerLogo from "../images/logos/FlexiTime/Partners/FlexiTime_Partner.svg";
import GoldBadge from "../images/logos/FlexiTime/Partners/FlexiTime_Gold_Badge.svg";
import SilverBadge from "../images/logos/FlexiTime/Partners/FlexiTime_Silver_Badge.svg";
import BronzeBadge from "../images/logos/FlexiTime/Partners/FlexiTime_Bronze_Badge.svg";
import PayHeroLogo from "../images/logos/PayHero/PayHero_Full.svg";
import DroppahLogo from "../images/logos/Droppah/Droppah_Full_Black.svg";
import InvoxyLogo from "../images/logos/Invoxy/Invoxy_Full.svg";
import PayHeroSingleLogo from "../images/logos/PayHero/PayHero_Single.svg";
import DroppahSingleLogo from "../images/logos/Droppah/Droppah_Single.svg";
import InvoxySingleLogo from "../images/logos/Invoxy/Invoxy_Single.svg";

const PartnerList = styled(Row)`
  --item-gap: 25px;
  align-items: stretch;

  width: calc(100% + var(--item-gap));

  > div {
    width: calc((100%) - var(--item-gap));
    margin-right: var(--item-gap);
    margin-bottom: var(--item-gap);

    &.Bronze {
      width: calc((100% / 3) - var(--item-gap));
    }

    @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
      width: 100%;
      margin-right: 0;
    }
  }

  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    width: 100%;
  }
`;

const PartnerCard = styled(Row)`
  position: relative;
  border-radius: 20px;
  background: #fff;
  box-shadow: ${(props) => props.theme.styles.shadow};
  padding: 30px;
  /* transition: all ${(props) => props.theme.transitions.med}; */

  &.Gold {
    border: solid 2px #E1CC0A;
  }

  &.Bronze {
    flex-direction: column;
    justify-content: flex-start;

    > div {
      width: 100%;
      margin-left: 0;
      margin-right: 0;

      .logo {
        height: 120px;
        max-width: 100%;
        margin-bottom: 40px;

        > picture {
          
          img {
            height: auto!important;
            top: 50% !important;
            left: 50% !important;
            transform: translate(-50%, -50%);
            width: auto!important;
            max-height: 100%;
            max-width: 80%;
          }
        }

        @media (max-width: ${(props) => props.theme.breakpoints.tablet}) {
          height: 90px;
          margin-bottom: 10px;
        }
      }
    }
  }

  h3 {
    color: #000;
  }

  .description {

    p {
      color: #000 !important;
    }

    &.-reveal {

      p {
        display: block !important;
        overflow: auto !important;
      }
    }

     p:first-child {
      overflow: hidden;
      display: -webkit-box;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;
    }

    p {
      display: none;
    }
  }

  .read-more {
    color: ${(props) => props.theme.colours.deeppurple};
    cursor: pointer;
    display: table;
  }

  .logo {
    max-width: 200px;
  }

  @media (max-width: ${(props) => props.theme.breakpoints.tablet}) {
    width: calc((100% / 2) - 40px);
  }

  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    width: 100% !important;
    margin-right: 0;
  }
`;

const Sidebar = styled(Box)`
  position: sticky;
  top: 100px;
  padding: 3px;
  z-index: 98;

  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    top: 59px;
    background: #000;
    padding: 15px 2rem;
    width: calc(100% + 4rem);
    margin-left: -2rem;
  }
`;

const PartnerDirectory = (props) => {
  const PartnerData = useStaticQuery(graphql`
    query {
      allContentfulPartner(
        sort: { fields: clientAccounts, order: DESC }
        filter: { partnerStatusLevel: { in: ["Gold", "Silver", "Bronze"] } }
      ) {
        edges {
          node {
            logo {
              gatsbyImageData(layout: FULL_WIDTH, quality: 100)
            }
            name
            description {
              raw
            }
            email
            partnerWebsite
            contactNumber
            url
            serviceLocations
            region
            partnerStatusLevel
            clientAccounts
            payHeroCertified
            invoxyCertified
            droppahCertified
          }
        }
      }
    }
  `);

  const [location, setLocation] = useState("");
  const [cert, setCert] = useState("");
  const [reveal, setReveal] = useState();

  var partnerList = [];
  var noResults = false;
  var order = { Gold: 1, Silver: 2, Bronze: 3 };

  const handleLocationChange = (e) => {
    setLocation(e.target.value);
  };

  function handleReadMore(i) {
    if (reveal === i) {
      setReveal(false);
    } else {
      setReveal(i);
    }
  }

  const filterCert = (partner) => {
    if (cert === "PayHero") {
      return partner.payHeroCertified;
    } else if (cert === "Droppah") {
      return partner.droppahCertified;
    } else if (cert === "Invoxy") {
      return partner.invoxyCertified;
    } else {
      return true;
    }
  };

  const handleCertChange = (e) => {
    console.log(e.target.value);
    setCert(e.target.value);
  };

  function loadPartners() {
    partnerList = PartnerData.allContentfulPartner.edges
      .filter(function(partner) {
        if (
          (location ? partner.node.region === location : true) &&
          filterCert(partner.node)
        ) {
          return !!partner.node.partnerStatusLevel;
        } else {
          return false;
        }
      })
      .sort((a, b) => b.node.clientAccounts - a.node.clientAccounts)
      .sort(function(a, b) {
        return (
          order[a.node.partnerStatusLevel] - order[b.node.partnerStatusLevel]
        );
      })
      .map((edge, i) => {
        return (
          <PartnerCard
            className={edge.node.partnerStatusLevel}
            key={i}
            stackGap={50}
            alignCenter
          >
            <Box size={35} stackGap={40}>
              {/* <Box className="-center" size={100}> */}
              <GatsbyImage
                image={edge.node.logo.gatsbyImageData}
                className="-center logo"
                alt={`${edge.node.name} | FlexiTime Partner`}
              />
              {/* </Box> */}
              {edge.node.partnerStatusLevel === "Gold" && (
                <Button
                  className="-lg -full primary profileButton"
                  to={`/partner/${edge.node.url}`}
                >
                  See Profile
                </Button>
              )}
            </Box>
            <Box size={65} stackGap={25}>
              <Box stackGap={10}>
                <Row justify="flex-start" stackGap={10} alignCenter noBreak>
                  <img
                    src={
                      edge.node.partnerStatusLevel === "Gold"
                        ? GoldBadge
                        : edge.node.partnerStatusLevel === "Silver"
                        ? SilverBadge
                        : BronzeBadge
                    }
                    alt={`FlexiTime ${edge.node.partnerStatusLevel} Partner`}
                    height="30"
                  />
                  {edge.node.droppahCertified && (
                    <>
                      <div>·</div>
                      <div data-tooltip="Rostering Certified">
                        <img
                          src={DroppahSingleLogo}
                          alt="Rostering Certified Partner"
                          height="15"
                        />
                      </div>
                    </>
                  )}
                  {edge.node.payHeroCertified && (
                    <>
                      <div>·</div>
                      <div data-tooltip="Payroll Certified">
                        <img
                          src={PayHeroSingleLogo}
                          alt="Payroll Certified Partner"
                          height="16"
                        />
                      </div>
                    </>
                  )}
                  {edge.node.invoxyCertified && (
                    <>
                      <div>·</div>
                      <div data-tooltip="Contractor Management Certified">
                        <img
                          src={InvoxySingleLogo}
                          alt="Contractor Management Certified Partner"
                          height="15"
                        />
                      </div>
                    </>
                  )}
                </Row>
                <Box stackGap={5}>
                  <h3 className="-fontDark">{edge.node.name}</h3>
                  {edge.node.partnerStatusLevel !== "Bronze" && (
                    <>
                      <div
                        className={`description ${
                          reveal === i ? "-reveal" : ""
                        }`}
                      >
                        {renderRichText(edge.node.description)}
                      </div>
                      <p
                        className="read-more"
                        onClick={() => handleReadMore(i)}
                      >
                        {reveal === i ? "- Read Less" : "+ Read More"}
                      </p>
                    </>
                  )}
                </Box>
              </Box>
              <Box stackGap={17}>
                {edge.node.region && (
                  <div css={{ color: "#999", fontSize: "1.1rem" }}>
                    <IoLocation css={{ top: "1px", position: "relative" }} />{" "}
                    {edge.node.region}
                    {edge.node.region !== "All of New Zealand" && ", NZ"}
                  </div>
                )}
                {edge.node.partnerStatusLevel !== "Bronze" && edge.node.email && (
                  <div>
                    <a
                      className="link-floating"
                      href={`mailto:` + edge.node.email}
                    >
                      {edge.node.email}
                    </a>
                  </div>
                )}
                {edge.node.partnerStatusLevel !== "Bronze" &&
                  edge.node.contactNumber && (
                    <div>
                      <a
                        className="link-floating"
                        href={`tel:` + edge.node.contactNumber}
                      >
                        {edge.node.contactNumber}
                      </a>
                    </div>
                  )}
                {edge.node.partnerWebsite && (
                  <div>
                    <a
                      className="link-floating"
                      href={edge.node.partnerWebsite}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Visit Website{" "}
                      <IoMdOpen css={{ top: "3px", left: "3px" }} />
                    </a>
                  </div>
                )}
              </Box>
            </Box>
          </PartnerCard>
        );
      });

    if (partnerList.length < 1) {
      noResults = true;
    }

    return partnerList;
  }

  // const certifications = [
  //   {
  //     name: "",
  //     logo: null,
  //   },
  //   {
  //     name: "PayHero",
  //     logo: PayHeroLogo,
  //   },
  //   {
  //     name: "Droppah",
  //     logo: DroppahLogo,
  //   },
  //   {
  //     name: "Invoxy",
  //     logo: InvoxyLogo,
  //   },
  // ];

  return (
    <Layout>
      <Seo
        title="Partner Directory | Droppah Advisors"
        description="Need an Accountant or Bookkeeper to help with your rostering? Our Partner Directory makes it easy and is a great place to start."
        pathname={props.location.pathname}
      />
      <Container>
        <Wrapper stackGap={80} maxWidth={900} noPaddingBottom>
          <Box stackGap={40}>
            <Box stackGap={20} className="-textCenter">
              <div>
                <h1>Find a Partner</h1>
                <h4>
                  Our partners are accountants, bookkeepers and cloud technology
                  experts who can help you get set up or manage your Droppah
                  account on an ongoing basis.
                </h4>
              </div>
            </Box>
          </Box>
        </Wrapper>
      </Container>
      <Container css={{ overflow: "unset" }}>
        <Wrapper stackGap={80}>
          <Row stackGap={50}>
            <Sidebar size={25} stackGap={40}>
              {/* <Box>
                <p>
                  <b>Product Certification</b>
                </p>
                {certifications.map((product, i) => {
                  return (
                    <div
                      key={i}
                      className="checkbox-wrapper -select"
                      css={{ padding: "15px 0 !important" }}
                    >
                      <label css={{ justifyContent: "flex-start !important" }}>
                        <div>
                          <input
                            type="radio"
                            name="cert"
                            value={product.name}
                            onChange={handleCertChange}
                            checked={product.name === cert}
                          />
                          <div className="select-icon"></div>
                        </div>
                        {product.logo ? (
                          <img
                            src={product.logo}
                            height={product.name === "Invoxy" ? "21" : "20"}
                            alt={
                              product.name +
                              ` Product Certification | FlexiTime Partner`
                            }
                          />
                        ) : (
                          <p css={{ marginBottom: "0", fontWeight: "500" }}>
                            Any Certification
                          </p>
                        )}
                      </label>
                    </div>
                  );
                })}
              </Box> */}
              <Box>
                <p className="hideOnMobile">
                  <b>Service Location</b>
                </p>
                <div className="select-wrapper">
                  <select
                    aria-label="Filter FlexiTime Partner Directory"
                    defaultValue=""
                    onChange={handleLocationChange}
                  >
                    <option value="">Any Region</option>
                    <option value="All of New Zealand">
                      All of New Zealand
                    </option>
                    <option value="Auckland">Auckland, NZ</option>
                    <option value="Bay of Plenty">Bay of Plenty, NZ</option>
                    <option value="Canterbury">Canterbury, NZ</option>
                    <option value="Chatham Islands">Chatham Islands, NZ</option>
                    <option value="Gisborne">Gisborne, NZ</option>
                    <option value="Hawke's Bay">Hawke's Bay, NZ</option>
                    <option value="Manawatu - Whanganui">
                      Manawatu - Whanganui, NZ
                    </option>
                    <option value="Marlborough">Marlborough, NZ</option>
                    <option value="Nelson">Nelson, NZ</option>
                    <option value="Northland">Northland, NZ</option>
                    <option value="Otago">Otago, NZ</option>
                    <option value="Southland">Southland, NZ</option>
                    <option value="Taranaki">Taranaki, NZ</option>
                    <option value="Tasman">Tasman, NZ</option>
                    <option value="Waikato">Waikato, NZ</option>
                    <option value="Wairarapa">Wairarapa, NZ</option>
                    <option value="Wellington">Wellington, NZ</option>
                    <option value="West Coast">West Coast, NZ</option>
                  </select>
                  <IoIosArrowDown className="dropdown-arrow" />
                </div>
              </Box>
            </Sidebar>
            <Box size={75}>
              <PartnerList isWrap justify="flex-start">
                {loadPartners()}
              </PartnerList>
              <div css={{ marginTop: "0 !important" }}>
                <EmptyState
                  show={noResults}
                  message="Couldn't find any partners in the selected region"
                />
              </div>
            </Box>
          </Row>
        </Wrapper>
      </Container>
    </Layout>
  );
};

export default PartnerDirectory;
